.com-select-color {
    position: absolute;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    padding: 4px;
    background-color: white;
    border-radius: 4px;
    width: 416px;
    z-index: 1;
    margin-left: -4px;
}

.com-select-color>input {
    width: 450px;
}