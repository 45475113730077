.delete-company-btn {
    visibility: hidden;
}

.ant-table-cell-row-hover .delete-company-btn {
    visibility: visible;
}

.delete-company-btn:hover {
    color: var(--primary-color) !important;
}
