.cad {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    position: absolute;
    left: 8px;
    top: 4px;
    background-color: #d9d9d9;
    padding: 8px;
    width: calc(100% - 16px);
    height: calc(100% - 24px);
    z-index: 1;
    border-radius: 8px;
    box-shadow: 2px 2px 4px #D9D9D9;
}
