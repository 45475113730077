.delete-department-btn {
    visibility: hidden;
}

.ant-table-cell-row-hover .delete-department-btn {
    visibility: visible;
}

.delete-department-btn:hover {
    color: var(--primary-color) !important;
}
