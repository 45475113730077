.commands-container {
    padding-bottom: 10px;
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
}


.toolbar-title {
    font-size: 20px;
    font-weight: 600;
}