.cad-prop{
  width: 300px;
  margin: 4px;
}

.cad-prop-section {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.cad-prop-title {
  font-weight: 600;
}

.cad-prop-price {
  font-weight: 600;
  font-size: 24px;
  text-align: center;
}