.auth-body {
    display: flex;
    flex-direction: column;
    background: #000000;
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    text-align: center;
}

.auth-container {
    flex: 1 0 auto;
    background-color: white;
    font-family: 'Roboto', Arial, sans-serif;
}

.auth-title {
    font-size: 56px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 7.5px;
    margin-top: 32px;
    /*text-transform: uppercase;*/
    color: #006abd;
}

.auth-sub-title {
    font-size: 15px;
    line-height: 2.6;
    font-weight: 300;
    letter-spacing: 0.5px;
    /*text-transform: uppercase;*/
}

.auth-form {
    max-width: 350px;
    min-width: 300px;
    position: relative;
    margin: 32px auto auto;
    /*border: 1px solid #666666;*/
    border-radius: 16px;
    padding: 32px;
    font-weight: 500;
    background: #006abd;
    color: #ffffff;
}

.auth-form-title {
    align-items: center;
    text-align: center;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 20px;
    /*text-transform: uppercase;*/
    font-weight: 600;
}

.auth-form-item {
    margin-bottom: 24px;
}

.auth-form-item-input {
    background-color: #ffffff;
}

.ant-input-affix-wrapper-disabled.auth-form-item-input {
    background-color: rgba(249, 249, 249, 0.75) !important;
}

.auth-form-btn {
    margin-top: 20px;
    width: 100%;
    text-transform: uppercase;
    font-weight: 500;
    background-color: #ff8129 !important;
    color: white !important;
    font-size: 20px !important;
    height: 56px !important;
}

.auth-support-container {
    flex-shrink: 0;
}

.auth-support {
    font-size: 22px;
    line-height: 1.8;
    font-weight: 300;
    letter-spacing: 0.5px;
    margin: 20px;
}

.auth-support-phone {
    font-size: 20px;
}

.auth-create {
    margin-top: 16px;
    font-size: 16px;
}

.auth-create-btn {
    font-weight: 600;
    color: #006abd;
    cursor: pointer;
}