.oper-props-body {
    
    /*width: 544px;*/
    /*height: 200px;*/
    margin: "auto";
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.oper-props-item {
    /*background-color: #d9d9d9;*/
    height: 32px;
    display: flex;
    align-items: center;
}

.oper-props-item-title {
    width: 104px;
    text-align: end;
}

.oper-props-item-select {
    padding: 4px 8px;
}

.oper-props-item-string {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    padding: 4px 8px;
}

.oper-props-item-string>input {
    margin-right: 8px;
}

.input-disabled {
    border: none;
}

.oper-props-number {
    margin-right: 8px;
}
/* .oper-props-number {
    background-color: #d9d9d9;
    color: black;
    padding: 4px;
    border-radius: 32px;
    width: 20px;
    height: 20px;
    font-weight: bold;
    -moz-border-radius: 32px;
    display: inline-block;
    text-align: center;
    margin-right: 8px;
} */


.oper-props-buttons {
    margin-left: 112px;
    column-gap: 4px;
}