.ant-layout-header {
    background-color: #ffffff;
}

.ant-layout-sider,
.ant-layout-sider .ant-menu,
.ant-menu-item,
.ant-menu-submenu,
.ant-menu-submenu-title {
    /*background-color: #3e4657 !important;*/
    /*color: #ffffff;*/
}

.ant-btn-primary {
    color: #000000 !important;
}

.ant-btn-link {
    color: #000000;
}

.ant-btn-primary {
    background-color: var(--primary-color) !important ;
}

.ant-modal-content {
    /*  padding: 0 !important; */
}

.ant-menu-item-selected {
    background: #002140 !important;
    color: #ffffff !important;
}

.ant-menu-item-active {
    background: #002140 !important;
    color: #ffffff !important;
}

.ant-form-item {
    margin-bottom: 15px;
}

.ant-table-expanded-row .ant-table-thead > tr > th {
    background: #f6fefa;
}

.ant-table-expanded-row .ant-table-cell {
    background-color: #ffffff;
}

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
    background-color: #ffffff;
}

.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-start-radius: 0;
}

.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
    border-start-end-radius: 0;
}

.ant-input[disabled] {
    color: #000000;
}

.ant-layout {
    background-color: #ffffff;
}

.ant-pagination-item:hover a,
.ant-pagination-item-link:hover,
.ant-btn-link:hover {
    color: #74a3c7 !important;
}

.ant-pagination-item-active a {
    color: #74a3c7 !important;
}

.ant-btn-default:hover {
    border-color: #74a3c7 !important;
    color: #74a3c7 !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: none;
    /*background-color: #3e4657 !important;*/
}
.ant-select .ant-select-selection-placeholder {
    color: rgba(0, 0, 0, 0.5) !important;
}

.ant-layout-sider {
    height: 100vh;
    min-height: 100vh;
}

.ant-notification-notice-description {
    margin-left: 0 !important;
}

.ant-input-group-wrapper-disabled .ant-input-group-addon,
.ant-input-number-disabled,
.ant-select-disabled .ant-select-selector {
    color: #000000 !important;
}

.ant-menu-horizontal {
    justify-content: right;
}

.ant-steps-item-title {
    font-size: 12px !important;
}

.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
    color: var(--primary-color);
}

.ant-layout .ant-layout-sider-children {
    height: calc(100vh - 48px) !important;
    overflow: auto;
}

.rc-virtual-list-scrollbar,
.ant-table-body::-webkit-scrollbar,
.ant-layout-sider-children::-webkit-scrollbar {
    width: 0.45em !important;
    height: 0.45em !important;
}

.rc-virtual-list-scrollbar-thumb,
.ant-table-body::-webkit-scrollbar-thumb,
.ant-layout-sider-children::-webkit-scrollbar-thumb {
    background-color: var(--primary-color) !important;
    border-radius: 50px !important;
}


.ant-tree-node-selected {
    background-color: #74a3c7 !important;
}