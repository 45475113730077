.selected-row {
    background-color: #ffdd2d !important; /* Цвет фона для выбранной строки */
}

.furniture-table .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: inherit !important; /* Сохранение фона при наведении */
}

.parameter-table .ant-select {
    width: 100%;
}

.parameter-table .ant-table-tbody > tr > td {
    padding: 8px; /* Уменьшает отступы внутри ячеек */
}

.parameter-table .ant-table-thead > tr > th {
    padding: 8px; /* Уменьшает отступы в заголовках */
}

.parameter-table .ant-table-tbody > tr {
    height: 32px; /* Уменьшает высоту строк таблицы */
}