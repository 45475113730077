.item-img>svg {
    display: block;
    padding: 4px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    width: 100px;
    height: 100px;
}

.order-item-ordno {
    position: absolute;
    left: 8px;
    top: 8px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: black;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
}

.order-item-cell {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.order-item-row{
    vertical-align: top;
}

.order-head-row-item {
    margin-bottom: 4px;
}

.order-item-syma {
    font-size: 18px;
    font-weight: bold;
}

.orders-col-actions {
    display: flex;
}

.order-total {
    padding: 4px;
}

.order-total-syma {
    text-align: right;
    font-size: 24px;
    font-weight: 600;
}