@import '@src/core/ant-custom.css';

:root {
    --primary-color: #ffdd2d;
}

body {
    margin: 0;
}

.logo {
    /*height: 32px;*/
    margin: 10px 0 15px;
    font-family: 'DrukTextWideTT', Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    align-items: center;
    text-align: center;
    letter-spacing: 1.75px;
    /*color: #ffffff;*/
    letter-spacing: 1.75px;
}

.sub-logo {
    font-weight: 600;
    font-size: 14px;
    margin-top: -5px;
    text-transform: uppercase;
    color: var(--primary-color);
}

.logout {
    font-family: 'Rubik', Arial, sans-serif;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}

.user {
    font-family: 'Rubik', Arial, sans-serif;
    font-size: 19px;
    line-height: 22.27px;
}

.main-header {
    padding: 0;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    margin-bottom: 2px;
}

.main-content {
    padding: 15px 15px 0;
    overflow: auto;
    /*max-width: 1200px;*/
}

.processing {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.45);
}

.processing-area {
    background-color: #3e4657;
    padding: 20px;
}

.user-account-info {
    background-color: #3e4657;
    color: #ffffff;
    padding: 0 20px;
    cursor: default;
}

.trackingUrl {
    margin-left: 10px;
    color: var(--primary-color);
}

.mainWarehouseTag {
    background-color: var(--primary-color);
    font-weight: 700;
    padding: 0px 4px;
    font-size: 11px;
    width: fit-content;
    margin: auto;
    border: 1px solid rgb(62, 70, 87);
}

.transitWarehouseTag {
    background-color: rgb(62, 70, 87);
    color: rgb(255, 255, 255);
    font-weight: 700;
    padding: 0px 4px;
    font-size: 11px;
    width: fit-content;
    margin: auto;
}

.country-form-item {
    margin: 0;
    min-height: 22px;
    height: 22px;
    text-align: left;
}

.support-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.support {
    font-size: 16px;
    font-family: 'Rubik', Arial, sans-serif;
    line-height: 1.8;
    font-weight: 300;
    letter-spacing: 0.5px;
    width: 100%;
    margin-bottom: 10px;
}

.support-phone {
    font-size: 18px;
}

.delivery-option {
    color: var(--primary-color);
    margin: -5px;
}

.icon-background {
    border: 1px solid;
    padding: 7px;
    border-radius: 50%;
    background: #3e4657 !important;
}
