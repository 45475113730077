.cad-toolbar {
    display: flex;
    flex-direction: column;
    padding: 4px;
    row-gap: 4px;
    margin-right: 8px;
}

.cad-tool-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 32px;
    height: 32px;
    justify-content: center;
    border-radius: 4px;
    margin-bottom: 2px;
    cursor: pointer;
}

.cad-tool-button-disabled {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 32px;
    height: 32px;
    justify-content: center;
    border-radius: 4px;
    margin-bottom: 2px;    
}

.cad-tool-icon {
    font-size: 32px;
}

.cad-tool-label {
    font-size: 10px;
    text-align: center;
}

.cad-tool-img {
    width: 28px;
    height: 28px;
}

.svg-cons {
    stroke: black;
    stroke-width: 2px;
}

.svg-select {
    stroke: #ff8129;
    stroke-width: 2px;
}

.svg-cons-disabled {
    stroke: #898989;
    stroke-width: 1px;
}

.svg-select-disabled {
    stroke: #d9d9d9;
    stroke-width: 1px;
}

.cad-tool-button:hover {
    background-color: #006abd;
    svg>.svg-select-fill {
        fill: #f70000;
        stroke-width: 2px;
    }
}

.svg-select-fill {
    fill: #ff8129;
    stroke-width: 2px;
}